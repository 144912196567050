import React, { useEffect, useMemo, useState } from 'react'
import { css } from '@emotion/core'
import { Container, Box, Grid } from '@material-ui/core'
import * as Yup from 'yup'

import * as utils from '~/utils'
import { Theme } from '~/styles'
import {
  Heading,
  FormError,
  Typography,
  Button,
  Select,
  DateWithValidation,
  Header,
  Input,
  Main,
} from '~/components'
import {
  getURLSignature,
  NewPatientFormState,
  useAccountFormNavigation,
  usePartnerData,
  useStates,
  usePronouns,
  useGenderIdentities,
  NewPatientNameDate,
  MemberIdConfirmationPayLoad,
  GENDERS_DICT,
  ELIGIBLE_INSURANCE,
  usePayers,
  INSURANCE_SOURCE,
  usePreferredLanguages,
  PreferredLanguageEntry,
  getReferralSource,
} from './utils'
import { useTenantConfigQuery } from '~/utils/useTenantConfigQuery'
import Loader from '~/components/Loader/Loader'
import { Formik } from 'formik'
import { handleChange, handleSelect, handleBlur } from '~/utils/formik'
import { secureCache } from '~/utils/secureCache'
import { useHistory, useParams } from 'react-router-dom'
import { ReactComponent as ErrorIcon } from '~/assets/icons/material/error.svg'
import { PreferredLanguage } from '~/types'
import { logEvent } from '~/utils/events'
import { useFeatureSwitchesQuery } from '~/api/queries/features'
import EmployeeIdentifierInput from '~/components/input/EmployeeIdentifierInput'
import { employers } from '~/constants/SignUp'
import { format, parse } from 'date-fns'

export interface AccountNameFormProps {
  newPatientNameFormDate: NewPatientNameDate
}

const DEFAULT_LANGUAGE_NAME = 'English'
const OTHER_LANGUAGE_NAME = 'Other'
const OTHER_LANGUAGE_LABEL = 'I prefer another language'
const ALLOWED_LANGUAGES = [DEFAULT_LANGUAGE_NAME, OTHER_LANGUAGE_NAME]

export const AccountForm: React.FC<AccountNameFormProps> = props => {
  const accountFormNavigation = useAccountFormNavigation()
  const history = useHistory<{ accountNameDate?: NewPatientNameDate }>()
  const { data: tenantData, isLoading: tenantConfigLoading } = useTenantConfigQuery()
  const signature = getURLSignature()
  const inputData = usePartnerData(signature)
  const states = useStates()
  const preferredLanguages = usePreferredLanguages()
  const pronouns = usePronouns()
  const genderIdentities = useGenderIdentities()
  const payers = usePayers()
  const [tenantKey, setTenantKey] = useState(tenantData?.key)
  const { employer } = useParams<{ employer: string }>()
  const tenantConsentForms = tenantData?.consentForms?.filter(
    consentForm => consentForm.needsSignature
  )

  const { isLoading: featureSwitchesLoading } = useFeatureSwitchesQuery()
  const isInsuranceFieldIncluded = tenantKey !== 'simplifed' && employers.includes(employer)

  const validationSchema = Yup.object().shape({
    dob: utils.dobSchema,
    sex: Yup.object().required('Sex assigned at birth is required').nullable(),
    state: Yup.object()
      .shape({ label: Yup.string(), value: Yup.string() })
      .nullable()
      .required('State of residence is required'),
    sourceType: isInsuranceFieldIncluded ? utils.sourceTypeSchema : '',
    insurancePayer: isInsuranceFieldIncluded ? utils.insurancePayerSchema : '',
    preferredLanguage: Yup.object()
      .shape({ label: Yup.string(), value: Yup.string() })
      .nullable()
      .required('Preferred language is required'),
    preferredLanguageOther: Yup.string().when('preferredLanguage', {
      is: preferredLanguage => preferredLanguage.name === OTHER_LANGUAGE_NAME,
      then: utils.PreferredLanguageOtherSchema,
    }),
    ...(employers.includes(employer) && {
      employeeIdentifier: utils.employeeIdentifierSchema(employer),
    }),
  })
  const isLoading =
    pronouns.isLoading ||
    states.isLoading ||
    payers.isLoading ||
    tenantConfigLoading ||
    featureSwitchesLoading ||
    preferredLanguages.isLoading
  useEffect(() => {
    setTenantKey(tenantData?.key)
  }, [tenantData, tenantConfigLoading])

  const mapToPreferredLanguageData = (
    preferredLanguage: PreferredLanguage
  ): PreferredLanguageEntry => {
    return {
      name: preferredLanguage.name,
      label: preferredLanguage.label,
      value: String(preferredLanguage.value),
    }
  }

  const getPreferredLanguageData = (
    preferredLanguages: PreferredLanguage[] | undefined
  ): PreferredLanguageEntry[] | undefined => {
    if (preferredLanguages) {
      preferredLanguages = preferredLanguages
        .filter(prefLanguage => ALLOWED_LANGUAGES.includes(prefLanguage.name))
        .map(prefLang =>
          prefLang.name === OTHER_LANGUAGE_NAME
            ? { ...prefLang, label: OTHER_LANGUAGE_LABEL }
            : prefLang
        )
      return preferredLanguages.map(mapToPreferredLanguageData)
    } else {
      return undefined
    }
  }

  const defaultPreferredLanguage = useMemo(() => {
    const defaultLanguage = preferredLanguages?.data?.find(
      prefLang => prefLang.name === DEFAULT_LANGUAGE_NAME
    )
    return defaultLanguage ? mapToPreferredLanguageData(defaultLanguage) : null
  }, [preferredLanguages.data])

  let initialState: NewPatientFormState
  if (inputData.data) {
    initialState = {
      ...inputData.data,
      insurancePayer: null,

      dob: inputData?.data?.dob || '',
      phoneNumber: inputData?.data?.phoneNumber || '',
      sourceType: null,
      state: states.data
        ? states.data.find(
            state => state.value === inputData?.data?.insuranceInfo?.addresses[0]?.state
          )
        : null,
      preferredLanguage: defaultPreferredLanguage,
      preferredLanguageOther: '',
    }
  } else {
    if (!secureCache.get('firstName')) history.push({ pathname: '/signup/name' })

    initialState = {
      firstName: secureCache.get('firstName') || '',
      lastName: secureCache.get('lastName') || '',
      preferredName: secureCache.get('preferredName') || '',
      termsAndConditions: (secureCache.get('termsAndConditions') === 'true') as boolean,
      consentForms: JSON.parse(JSON.stringify(secureCache.get('consentForms')) || '[]') as number[],
      dob: secureCache.get('dob') || '',
      insurancePayer: JSON.parse(secureCache.get('insurancePayer') || 'null'),
      phoneNumber: secureCache.get('phoneNumber') || '',
      pronouns: JSON.parse(secureCache.get('pronouns') || 'null'),
      gender: JSON.parse(secureCache.get('gender') || 'null'),
      sex: JSON.parse(secureCache.get('sex') || 'null'),
      sourceType: JSON.parse(secureCache.get('sourceType') || 'null'),
      state: JSON.parse(secureCache.get('state') || 'null'),
      preferredLanguage: JSON.parse(
        secureCache.get('preferredLanguage') || JSON.stringify(defaultPreferredLanguage)
      ),
      preferredLanguageOther: secureCache.get('preferredLanguageOther') || '',
      ...(employers.includes(employer) && {
        employeeIdentifier: secureCache.get('employeeIdentifier') || '',
      }),
    }
  }

  // eslint-disable-next-line.
  return isLoading ? (
    <>
      <Header />
      <Loader />
    </>
  ) : (
    <>
      <Header />
      <Container>
        <Box mt={10}>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12} md={6} lg={5}>
              <Main px={{ xs: 0 }}>
                <Heading>Tell us a bit more about yourself</Heading>
                <Typography variant="subText">
                  Your answers help us provide the right care for your specific needs.
                </Typography>
                <Box mt={3} />
                {accountFormNavigation.error &&
                  accountFormNavigation.error.message &&
                  !accountFormNavigation.loading && (
                    <Box mb={1}>
                      {employer ? (
                        <FormError>
                          We need to verify your information. Call us at{' '}
                          <a href="tel:888-897-1887" title="Call us">
                            (888) 897-1887
                          </a>{' '}
                          to create your account.{' '}
                        </FormError>
                      ) : (
                        <FormError>
                          Oops! Something went wrong. Do you already have an account with us?{' '}
                          <a href="http://onelink.to/tyayzv">Download the Firefly Health app</a>.
                        </FormError>
                      )}
                    </Box>
                  )}
                <Formik<NewPatientFormState>
                  isInitialValid={true}
                  validateOnChange={false}
                  initialValues={initialState}
                  enableReinitialize={true}
                  onSubmit={values => {
                    for (const value in values) {
                      if (typeof values[value] === 'string') secureCache.set(value, values[value])
                      else secureCache.set(value, JSON.stringify(values[value]))
                    }
                    values.phoneNumber = utils.parsePhoneNumber(values.phoneNumber)
                    if (values.gender) values.gender = [values.gender.value]
                    if (values.sex) values.sex = values.sex.label
                    if (values.pronouns) values.pronouns = values.pronouns.label
                    values.employer = employer
                    const payload: MemberIdConfirmationPayLoad = {
                      firstName: values.firstName,
                      lastName: values.lastName,
                      phoneNumber: values.phoneNumber,
                      preferredName: values.preferredName,
                      employeeIdentifier: values.employeeIdentifier,
                      sex: values.sex,
                      gender: values.gender,
                      pronouns: values.pronouns,
                      dob: format(parse(values.dob, 'MM/dd/yyyy', new Date()), 'yyyy-MM-dd'),
                      createdFrom: 'web',
                      patientReferralProgram: getReferralSource(employer),
                      preferredLanguage: Number(values.preferredLanguage?.value) || null,
                      preferredLanguageOther:
                        values.preferredLanguage?.name === OTHER_LANGUAGE_NAME
                          ? values.preferredLanguageOther
                          : null,
                      insuranceMemberInfo: {
                        state: values.state.value,
                        sourceType: values.sourceType?.value,
                        insurancePayerId: values.insurancePayer.id,
                      },
                    }
                    payload.consentForms = tenantConsentForms?.map(f => f.id)
                    secureCache.set('acct_payload', JSON.stringify(payload))
                    logEvent('DEMOGRAPHIC_SCREEN_COMPLETED', {})
                    // We hit the API before accepting terms for checking duplicate account and show errors
                    accountFormNavigation.handler(values)
                  }}
                  validationSchema={validationSchema}
                >
                  {formik => {
                    return (
                      <form onSubmit={formik.handleSubmit} css={styles.wrapper}>
                        <Box mb={2}>
                          <DateWithValidation
                            data-cy="dob"
                            values={formik.values}
                            errors={formik.errors}
                            error={formik.touched.dob ? formik.errors.dob : void 0}
                            onChange={handleChange(formik, 'dob')}
                            onBlur={handleBlur(formik, 'dob')}
                          />
                        </Box>
                        <Box mb={2}>
                          <EmployeeIdentifierInput
                            errors={formik.errors}
                            error={
                              formik.touched.employeeIdentifier
                                ? formik.errors.employeeIdentifier
                                : void 0
                            }
                            values={formik.values}
                            value={formik.values.employeeIdentifier}
                            employer={employer}
                            name="employeeIdentifier"
                            required
                            onChange={handleChange(formik, 'employeeIdentifier')}
                            onBlur={handleBlur(formik, 'employeeIdentifier')}
                          />
                        </Box>
                        <Box mb={2}>
                          <Select
                            data-cy="state"
                            onChange={handleSelect(formik, 'state')}
                            error={formik.touched.state && !!formik.errors.state}
                            placeholder="State of residence"
                            options={states.data}
                            value={formik.values.state}
                          />
                          {formik.touched?.state && formik.errors?.state && (
                            <Typography el="span" css={styles.errorText}>
                              {formik.errors?.state}
                            </Typography>
                          )}
                        </Box>
                        <Box mb={2}>
                          <Select
                            data-cy="sex"
                            onChange={handleSelect(formik, 'sex', false)}
                            error={formik.touched.sex && !!formik.errors.sex}
                            placeholder="Sex assigned at birth"
                            options={GENDERS_DICT}
                            value={formik.values.sex}
                          />
                          {formik.touched?.sex && formik.errors?.sex && (
                            <div css={styles.errorDiv}>
                              <ErrorIcon css={styles.errorIcon} />
                              <Typography el="span" css={styles.errorText}>
                                {formik.errors?.sex}
                              </Typography>
                            </div>
                          )}
                        </Box>
                        <Box mb={2}>
                          <Select
                            data-cy="pronouns"
                            onChange={handleSelect(formik, 'pronouns', false)}
                            error={formik.touched.pronouns && !!formik.errors.pronouns}
                            placeholder="Pronouns (optional)"
                            options={pronouns.data}
                            value={formik.values.pronouns}
                          />
                        </Box>
                        <Box mb={2}>
                          <Select
                            data-cy="gender"
                            onChange={handleSelect(formik, 'gender', false)}
                            error={formik.touched.gender && !!formik.errors.gender}
                            placeholder="Gender identity (optional)"
                            options={genderIdentities.data}
                            value={formik.values.gender}
                          />
                        </Box>
                        {isInsuranceFieldIncluded && (
                          <Box mb={2}>
                            <Select
                              data-cy="sourceType"
                              onChange={handleSelect(formik, 'sourceType', true)}
                              // @ts-ignore
                              error={
                                formik.touched.sourceType && formik.errors?.sourceType
                                  ? (formik.errors?.sourceType as string)
                                  : void 0
                              }
                              placeholder="Do you have health insurance?"
                              options={INSURANCE_SOURCE}
                              value={formik.values.sourceType}
                            />
                            {formik.touched?.sourceType && formik.errors?.sourceType && (
                              <div css={styles.errorDiv}>
                                <ErrorIcon css={styles.errorIcon} />
                                <Typography el="span" css={styles.errorText}>
                                  {formik.errors?.sourceType}
                                </Typography>
                              </div>
                            )}
                          </Box>
                        )}
                        {isInsuranceFieldIncluded &&
                          ELIGIBLE_INSURANCE.has(formik.values.sourceType?.value || '') && (
                            <Box mb={2}>
                              <Select
                                data-cy="insurancePayer"
                                onChange={handleSelect(formik, 'insurancePayer', false)}
                                error={
                                  formik.touched.insurancePayer && !!formik.errors.insurancePayer
                                }
                                placeholder="Insurance Payer"
                                options={payers.data}
                                value={formik.values.insurancePayer}
                              />
                              {formik.touched?.insurancePayer && formik.errors?.insurancePayer && (
                                <div css={styles.errorDiv}>
                                  <ErrorIcon css={styles.errorIcon} />
                                  <Typography el="span" css={styles.errorText}>
                                    {formik.errors?.insurancePayer}
                                  </Typography>
                                </div>
                              )}
                            </Box>
                          )}
                        <Box mb={2}>
                          <Select
                            data-cy="preferredLanguage"
                            onChange={handleSelect(formik, 'preferredLanguage')}
                            error={
                              formik.touched.preferredLanguage && !!formik.errors.preferredLanguage
                            }
                            placeholder="Preferred language"
                            options={getPreferredLanguageData(preferredLanguages.data)}
                            value={formik.values.preferredLanguage}
                          />
                          {formik.touched?.preferredLanguage && formik.errors?.preferredLanguage && (
                            <Typography el="span" css={styles.errorText}>
                              {formik.errors?.preferredLanguage}
                            </Typography>
                          )}
                        </Box>
                        {formik.values.preferredLanguage?.name === OTHER_LANGUAGE_NAME && (
                          <Box mb={2}>
                            <Input
                              data-cy="preferredLanguageOther"
                              label="Preferred language"
                              name="preferredLanguageOther"
                              error={
                                formik.touched.preferredLanguageOther
                                  ? formik.errors.preferredLanguageOther
                                  : void 0
                              }
                              value={formik.values.preferredLanguageOther || ''}
                              autoComplete="language"
                              onChange={handleChange(formik, 'preferredLanguageOther')}
                              onBlur={handleBlur(formik, 'preferredLanguageOther')}
                            />
                          </Box>
                        )}
                        <Box my={5}>
                          <Button
                            data-cy="submit"
                            fullWidth
                            type="submit"
                            disabled={isLoading}
                            css={styles.nextButton}
                            loading={accountFormNavigation.loading}
                          >
                            Next
                          </Button>
                        </Box>
                      </form>
                    )
                  }}
                </Formik>
              </Main>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  )
}

const styles = {
  button: css({
    padding: 0,
    fontWeight: 600,
  }),
  wrapper: () => css({ width: '100%' }),
  image: () =>
    css({
      marginTop: '8.0625em',
    }),
  genderLabel: (theme: Theme) =>
    css({
      color: theme.colors.gray['600'],
    }),
  errorText: (theme: Theme) =>
    css({
      fontSize: '1.2rem',
      color: theme.colors.red[700],
    }),
  stepText: (theme: Theme) =>
    css({
      fontSize: '1.5rem',
      fontWeight: 'normal',
      display: 'block',
      marginTop: '2.875em',
      paddingBottom: '0.5em',
      color: theme.colors.navy[500],
    }),
  subText: (theme: Theme) =>
    css({
      fontSize: '18px',
      lineHeight: '24px',
      fontFamily: 'Source Sans Pro',
      fontWeigth: '400',
      color: theme.colors.navy[500],
    }),
  errorDiv: (theme: Theme) =>
    css({
      flex: 1,
      flexDirection: 'row',
      display: 'inline',
      marginTop: '4px',
    }),
  errorIcon: (theme: Theme) =>
    css({
      height: '13.3px',
      width: '13.3px',
      color: theme.colors.red[700],
      display: 'inline',
      marginTop: '4px',
      marginRight: '5px',
      marginBottom: '-2px',
    }),
  nextButton: (theme: Theme) =>
    css({
      marginTop: '5%',
      [theme.mediaQueries.lg]: {
        position: 'relative',
        marginTop: '1%',
      },
    }),
}
export default AccountForm
