export function trackSuccessPage() {
  if (window.saq) {
    window.saq('rt', 'JCyDdOl22bqBPtO9w_Kh2A')
    window.saq('conv', 'AoYrUj5IOle58ZN9B-AxGQ')
  }
  if (window.gtag)
    window.gtag('event', 'conversion', { send_to: 'AW-737558462/WdKvCM-fq-wCEL7_2N8C' })
}

export function trackAccountCreated(id: string, personId: string, phone: string) {
  try {
    if (window.gtag)
      window.gtag('event', 'conversion', { send_to: 'AW-737558462/Nd3xCPXK8O8BEL7_2N8C' })
    // Braze user is created from backend and we don't need to create a
    // braze session since no other braze related action is happening in member-web portal
    // const brazePersonAccountSwitch = getFeatureSwitch('braze.use_person_account')
    // if (window.braze) {
    //   if (brazePersonAccountSwitch) window.braze.changeUser(`person_${personId}`)
    //   else window.braze.changeUser(id)
    // }
  } catch (e) {
    console.warn('Analytics events error', e)
  }
}
