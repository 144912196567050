import * as React from 'react'
import { css } from '@emotion/core'
import { Container, Box, Grid } from '@material-ui/core'
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip'
import { Formik } from 'formik'
import * as Yup from 'yup'

import { Theme } from '~/styles'
import { Heading, Typography, Input, Button, Main, Header, FormError } from '~/components'
import { NewPatientMemeberIdData, useBffAppSignUpMemberIdConfirmation } from './utils'
import { handleChange, handleBlur } from '~/utils/formik'
import { secureCache } from '~/utils/secureCache'
import { useSetToken } from '~/utils'
import { styled } from '@mui/material/styles'
import { colors } from '~/styles/color'

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: colors.navy[500],
    color: colors.white,
    maxWidth: 500,
    fontSize: theme.typography.pxToRem(24),
    fontFamily: 'Source Sans Pro',
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    flex: 1,
    padding: 5,
    margin: 20,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: colors.navy[500],
    position: 'relative',
  },
}))

export const AccountMemberId: React.FC = () => {
  const accountPayload = JSON.parse(secureCache.get('acct_payload') || 'null')
  const [open, setOpen] = React.useState(false)

  const handleTooltipClose = () => {
    setOpen(false)
  }

  const handleTooltipOpen = () => {
    setOpen(true)
  }
  const setToken = useSetToken()
  const validationSchema = Yup.object().shape({
    memberId: Yup.string()
      .required('Member ID Number is required')
      .test(
        'Invalid Member ID Number',
        'Please enter the correct Member ID Number',
        (value: string = '') => {
          if (!value) return true
          return value.replace(/\D+/g, '').length >= 1
        }
      ),
  })
  const initialState = { memberId: '' }
  const memberIdconfirmation = useBffAppSignUpMemberIdConfirmation()

  return (
    <>
      <Header />
      <Container>
        <Box mt={10}>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={10} md={5}>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={12}>
                  <Main px={{ xs: 0 }}>
                    <Heading>Enter your health insurance Member ID number</Heading>
                    <Typography variant="subText">
                      Type all numbers, letters, and characters exactly as they appear on your
                      insurance card.
                    </Typography>
                    <HtmlTooltip
                      onClose={handleTooltipClose}
                      open={open}
                      PopperProps={{
                        disablePortal: true,
                      }}
                      title={
                        <Box flex={1} flexDirection="column" p={3}>
                          <Typography css={styles.toolTipHeading}>
                            Finding your Member ID number
                          </Typography>
                          <Typography css={styles.toolTipContent}>
                            Your Member ID is typically a 9-12 character mix of letters and numbers
                            on the front of your insurance card, often labeled as “Member ID”, “ID
                            Number”, “Subscriber ID”, or “ID #”.
                          </Typography>
                          <Box
                            display="flex"
                            flex={1}
                            flexDirection="column"
                            alignItems="flex-end"
                            mt={3}
                          >
                            <Button css={styles.toolTipButton} onClick={handleTooltipClose}>
                              Got it
                            </Button>
                          </Box>
                        </Box>
                      }
                      arrow
                      placement="top"
                    >
                      <Box css={styles.toolTipChildren}>
                        <Typography
                          variant="subText"
                          style={{
                            textDecoration: 'underline',
                          }}
                          pt={5}
                          onClick={handleTooltipOpen}
                        >
                          Where can I find this info?
                        </Typography>
                      </Box>
                    </HtmlTooltip>
                    <Box mt={3} />
                    {memberIdconfirmation.error &&
                      memberIdconfirmation.error.message &&
                      !memberIdconfirmation.loading && (
                        <Box mb={1}>
                          <FormError>
                            Oops! Something went wrong. Do you already have an account with us?{' '}
                            <a href="http://onelink.to/tyayzv">Download the Firefly Health app</a>.
                          </FormError>
                        </Box>
                      )}
                    <Formik<NewPatientMemeberIdData>
                      isInitialValid={true}
                      validateOnChange={false}
                      initialValues={initialState}
                      onSubmit={async values => {
                        accountPayload.insuranceMemberInfo.memberId = values.memberId
                        await setToken.handler()
                        await memberIdconfirmation.handler(accountPayload)
                      }}
                      validationSchema={validationSchema}
                    >
                      {formik => {
                        return (
                          <form onSubmit={formik.handleSubmit} css={styles.wrapper}>
                            <Box my={2}>
                              <Input
                                label="Member ID number"
                                name="memberId"
                                required
                                value={formik.values.memberId || ''}
                                error={formik.touched.memberId ? formik.errors.memberId : void 0}
                                onChange={handleChange(formik, 'memberId')}
                                onBlur={handleBlur(formik, 'memberId')}
                                aria-invalid={!!formik.errors.memberId}
                              />
                            </Box>
                            {/* <Box my={5}> */}
                            <Button
                              data-cy="submit"
                              type="submit"
                              css={styles.nextButton}
                              buttonColor={'#FFD714'}
                              textColor={'#131D2B'}
                              fontWeight={400}
                              loading={memberIdconfirmation.loading}
                            >
                              Next
                            </Button>
                            {/* </Box> */}
                          </form>
                        )
                      }}
                    </Formik>
                  </Main>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  )
}

const styles = {
  button: css({
    padding: 0,
    fontWeight: 600,
  }),
  image: () =>
    css({
      marginTop: '8.0625em',
    }),
  wrapper: () => css({ width: '100%' }),
  genderLabel: (theme: Theme) =>
    css({
      color: theme.colors.gray['600'],
    }),
  errorText: (theme: Theme) =>
    css({
      fontSize: '1.2rem',
      color: theme.colors.red[700],
    }),
  errorDiv: (theme: Theme) =>
    css({
      flex: 1,
      flexDirection: 'row',
      display: 'inline',
      marginTop: '4px',
    }),
  errorIcon: (theme: Theme) =>
    css({
      height: '13.3px',
      width: '13.3px',
      color: theme.colors.red[700],
      display: 'inline',
      marginTop: '4px',
      marginRight: '5px',
      marginBottom: '-2px',
    }),
  nextButton: (theme: Theme) =>
    css({
      marginTop: '1%',
      [theme.mediaQueries.lg]: {
        position: 'relative',
      },
    }),
  toolTipButton: (theme: Theme) =>
    css({
      padding: '2px',
      borderRadius: '100px',
      backgroundColor: theme.colors.navy[400],
      height: '45px',
      width: '80px',
      fontSize: '14px',
      lineHeight: '18px',
      fontFamily: 'Source Sans Pro',
      fontWeight: 600,
    }),
  toolTipHeading: (theme: Theme) =>
    css({
      fontFamily: 'Source Sans Pro',
      fontWeight: 600,
      fontSize: '18px',
      lineHeight: '24px',
    }),
  toolTipContent: (theme: Theme) =>
    css({
      fontFamily: 'Source Sans Pro',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '18px',
      marginTop: '5px',
    }),
  toolTipChildren: (theme: Theme) =>
    css({
      marginTop: 10,
      width: '230px',
    }),
}
export default AccountMemberId
